import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid bg-dark bg-img p-5 mb-5">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="display-4 text-uppercase text-white">
              Ocean Freight
            </h1>
            <Link to="/Home">Home</Link>
            <i className="far fa-square text-primary px-2" />
            <a href>Ocean Freight</a>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 text-center">
              <h1 className="mb-4">Ocean Freight</h1>
              <img
                className="img-fluid rounded  wow zoomIn"
                data-wow-delay="0.1s"
                src="img/Ocean Freight.jpg"
              />
            </div>
            <div className="col-lg-12">
              <p>
                Ocean Freight Shipping stands as a pinnacle of efficiency and
                excellence in transporting goods to diverse global destinations.
                With the vast expanse of oceans covering a significant portion
                of our planet's surface, it naturally emerges as the most
                efficient and accessible mode of transportation.
              </p>

              <p>
                This method of shipping finds favor across a multitude of
                industrialized nations around the world, owing to its array of
                advantages. Notably cost-effective when compared to air freight
                alternatives, ocean freight services offer a harmonious balance
                between affordability and reliability. The eco-friendliness of
                ocean freight services further shines through, contrasting with
                the substantial fuel consumption and significant carbon
                footprint left behind by air transportation. This makes ocean
                shipping a more sustainable choice that aligns with
                environmental concerns.
              </p>

              <p>
                At Ocean Freight Shipping, we stand as a beacon of
                trustworthiness and affordability in the shipping realm. Our
                services encompass a wide spectrum of items, ensuring that we
                cater to the diverse needs of our clients. With a focus on
                reliability, cost-effectiveness, and environmental
                responsibility, we provide comprehensive and effective shipping
                solutions that reflect our commitment to serving our clients and
                the planet.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
