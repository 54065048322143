import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid bg-dark bg-img p-5 mb-5">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="display-4 text-uppercase text-white">
              Cold Chain Logistic
            </h1>
            <Link to="/Home">Home</Link>
            <i className="far fa-square text-primary px-2" />
            <a href>Cold Chain Logistic</a>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 text-center">
              <h1 className="mb-4">Cold Chain Logistic</h1>
              <img
                className="img-fluid rounded  wow zoomIn"
                data-wow-delay="0.1s"
                src="img/Cold Chain Logistic.jpg"
              />
            </div>
            <div className="col-lg-12">
              <p>
                The cold chain constitutes a critical aspect of modern supply
                chains, encompassing the meticulous transport of
                temperature-sensitive commodities. This intricate process relies
                on an intricate interplay of thermal and refrigerated packaging
                techniques, coupled with strategic logistical planning, all
                aimed at safeguarding the pristine quality of these shipments.
                The paramount objective is to preserve the integrity of these
                products throughout their journey.
              </p>

              <p>
                Cold chain management entails a variety of methods to ensure the
                safe passage of temperature-sensitive items. These include the
                utilization of refrigerated trucks and railcars, which provide
                controlled environments during land transportation. Similarly,
                refrigerated cargo ships facilitate the sea transportation of
                perishable goods, while air cargo services offer swift and
                efficient air travel for maintaining optimal conditions.{" "}
              </p>

              <p>
                The implementation of a robust cold chain is essential across
                industries that deal with products susceptible to temperature
                fluctuations. Industries like pharmaceuticals, food and
                beverages, and certain chemicals heavily rely on the cold chain
                to guarantee that their goods remain unaltered during transit.
                Ensuring the successful cold chain execution demands a
                harmonious orchestration of technologies, regulations, and
                meticulous oversight, all working together to uphold the quality
                and safety of temperature-sensitive commodities.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
