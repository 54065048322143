import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid bg-dark bg-img p-5 mb-5">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="display-6 text-uppercase text-white">
              Business Trading Services
            </h1>
            <Link to="/Home">Home</Link>
            <i className="far fa-square text-primary px-2" />
            <a href>Business Trading Services</a>
          </div>
        </div>
      </div>
      <div className="container-fluid pt-5">
        <div className="container">
          <div
            className="section-title position-relative text-center mx-auto mb-5 pb-3"
            style={{ maxWidth: "600px" }}
          >
            <h1 className="display-6 text-uppercase">Aerospace & Aviation</h1>
          </div>
          <div className="row gx-5">
            <div
              className="col-lg-6 mb-5 mb-lg-0"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 "
                  src="img/Aerospace.jpg"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="col-lg-6 pb-5">
              <p>
              {companyname} plays an integral role in bolstering the entire aircraft
                manufacturing supply chain, providing unwavering support across
                various stages from sourcing and procurement to the
                replenishment of critical aerospace parts. Our global network of
                facilities operates around the clock, meticulously orchestrating
                timely deliveries of products to destinations across the world.
              </p>
              <p>
                One of our core competencies lies in supplier management,
                recognizing the evolving complexity of the aircraft
                manufacturing supply chain. As the number of vendors continues
                to rise, having a robust system in place to effectively oversee
                suppliers has never been more essential. Our cutting-edge
                technology equips you with the tools to assess supplier
                performance in alignment with purchase orders, delivery
                schedules, and production deadlines.
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div
            className="section-title position-relative text-center mx-auto mb-5 pb-3"
            style={{ maxWidth: "600px" }}
          >
            <h1 className="display-6 text-uppercase">Automotive</h1>
          </div>
          <div className="row gx-5">
            <div className="col-lg-6 pb-5">
              <p>
                A key aspect of our service lies in Purchase Order (PO)
                Management. Our cutting-edge, in-house developed PO Management
                System grants you real-time insights into the whereabouts of
                your containers—tracking their movement, storage, and arrival.
                Whether you're at home or in the office, accessing this
                information is effortless, ensuring that you are always in the
                loop.
              </p>
              <p>
                We recognize that a well-orchestrated supply chain is paramount
                in the automotive industry, where precision and punctuality are
                non-negotiable. With {companyname} by your side, you can rest assured
                that your automobile production line will operate seamlessly,
                backed by streamlined supplier management and a comprehensive PO
                Management System that keeps you informed and empowered.
              </p>
            </div>
            <div
              className="col-lg-6 mb-5 mb-lg-0"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 "
                  src="img/Automotive.jpg"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid pt-5">
        <div className="container">
          <div
            className="section-title position-relative text-center mx-auto mb-5 pb-3"
            style={{ maxWidth: "600px" }}
          >
            <h1 className="display-6 text-uppercase">Beverages</h1>
          </div>
          <div className="row gx-5">
            <div
              className="col-lg-6 mb-5 mb-lg-0"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 "
                  src="img/Beverages.jpg"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="col-lg-6 pb-5">
              <p>
                Delivering excellence in the beverage industry, our specialized
                logistics and storage solutions stand as a safeguard, ensuring
                the integrity of your drinks remains untarnished. With a
                comprehensive range of reliable multi-modal services tailored to
                cater to every beverage, we guarantee the preservation of
                quality throughout the journey. Our adept teams, strategically
                positioned worldwide, orchestrate efficient deliveries that not
                only meet but exceed expectations, leaving your customers with a
                favorable and satisfying experience.
              </p>
              <p>
                Whether it's the seamless distribution of beer, wine, or
                spirits, our expertise in handling customs and excise matters
                ensures a smooth passage for your products. We understand the
                intricacies of regulations, providing you with hassle-free
                solutions to navigate through any legalities that may arise. Our
                facilities, both wet and dry bonded, offer secure storage,
                further enhancing the assurance of uncompromised quality.
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div
            className="section-title position-relative text-center mx-auto mb-5 pb-3"
            style={{ maxWidth: "600px" }}
          >
            <h1 className="display-6 text-uppercase">Government & Defence</h1>
          </div>
          <div className="row gx-5">
            <div className="col-lg-6 pb-5">
              <p>
                With a meticulous approach, our team of professionals takes
                proactive measures to ensure the seamless and secure loading and
                unloading of your cargo at your designated site. Prior to the
                operation, we undertake thorough preparations to guarantee that
                the process unfolds smoothly and successfully. We understand the
                critical importance of this stage, and our expertise in cargo
                handling shines as we implement the necessary protocols to
                ensure a problem-free operation.
              </p>
              <p>
                Ensuring the unhindered movement of both cargo and personnel, we
                take charge of obtaining the essential clearances required for
                the operation. Our proactive approach means that no one is left
                stranded at the gate, streamlining the entire process and
                minimizing disruptions. We recognize the significance of a
                cohesive and coordinated effort, and our commitment extends to
                managing every aspect of the operation.
              </p>
            </div>
            <div
              className="col-lg-6 mb-5 mb-lg-0"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 "
                  src="img/Government & Defence.jpg"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid pt-5">
        <div className="container">
          <div
            className="section-title position-relative text-center mx-auto mb-5 pb-3"
            style={{ maxWidth: "600px" }}
          >
            <h1 className="display-6 text-uppercase">High Tech</h1>
          </div>
          <div className="row gx-5">
            <div
              className="col-lg-6 mb-5 mb-lg-0"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 "
                  src="img/High Tech.jpg"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="col-lg-6 pb-5">
              <p>
                Delivering comprehensive solutions that span the entire supply
                chain, we specialize in offering tailored services for the
                consumer electronics industry. Our expertise covers a wide
                spectrum of requirements, enabling us to effectively address
                your unique logistical needs. Whether you require streamlined
                inbound air and ocean consolidation solutions, efficient DC
                bypass programs, seamless Ecommerce fulfilment, or precise
                retail store distribution, {companyname} stands as your dedicated
                outsourced logistics partner.
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div
            className="section-title position-relative text-center mx-auto mb-5 pb-3"
            style={{ maxWidth: "600px" }}
          >
            <h1 className="display-6 text-uppercase">Industrial</h1>
          </div>
          <div className="row gx-5">
            <div className="col-lg-6 pb-5">
              <p>
              {companyname}'s comprehensive range of services tailored for the
                Aerospace and Aviation sectors encompasses a global scope of
                support for aircraft manufacturing logistics. Our expertise
                extends to the realm of both civilian and military aircraft
                operations, delivering top-tier fulfilment logistics solutions.
                Whether you're dealing with the intricacies of large-scale
                engine components or navigating the urgency of same-day
                transportation needs, {companyname} is your dedicated partner.
              </p>
              <p>
                Our proficiency in managing vendors and orchestrating complex
                supply chains allows us to thrive in the high-stakes environment
                of aerospace and aviation. We understand the criticality of
                meeting stringent deadlines and maintaining seamless operations
                across the globe. Whether it's streamlining manufacturing
                processes or optimizing supply chain strategies, our services
                are designed to cater to the unique demands of the industry.
              </p>
            </div>
            <div
              className="col-lg-6 mb-5 mb-lg-0"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 "
                  src="img/Industrial.jpg"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
