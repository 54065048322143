import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function About() {
  return (
    <>
      <Header />
      <div className="container-fluid bg-dark bg-img p-5 mb-5">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="display-4 text-uppercase text-white">Who We Are.</h1>
              <Link to="/Home">Home</Link>
              <i className="far fa-square text-primary px-2" />
              <a href>Who We Are.</a>
            </div>
          </div>
        </div>
          {/* About Start */}
          <div className="container-fluid pt-5">
        <div className="container">
          <div
            className="section-title position-relative text-center mx-auto mb-5 pb-3"
            style={{ maxWidth: "600px" }}
          >
            <h2 className="text-primary font-secondary">Who We Are..</h2>
            <h1 className="display-4 text-uppercase">
              Welcome To {companyname}
            </h1>
          </div>
          <div className="row gx-5">
            <div
              className="col-lg-6 mb-5 mb-lg-0"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100"
                  src="img/about.jpg"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="col-lg-6 pb-5">
              <h4 className="mb-4">
                Turning Logistics Challenges into Seamless Solutions
              </h4>

              <p>
                At {companyname}, we specialize in offering comprehensive
                Freight Forwarding and business trade solutions that enable you
                to expand your business's reach to every corner you desire.
                Drawing upon our deep understanding of both internal and
                external customer requirements, we approach our services
                strategically to provide an all-encompassing solution that sets
                your brand in motion. Our core philosophy revolves around
                delivering operational excellence, ensuring viable and
                cost-effective technology-enabled solutions for even the most
                complex logistics and supply chain management queries. We
                manifest this philosophy through our work, which is driven by a
                forward-thinking and progressive mindset.
              </p>
              <p>
                No matter the size of your business, whether it's small, medium,
                or large, we cater to every technological need your business may
                have, adding a contemporary touch that keeps your operations
                moving smoothly. Our commitment to innovation is unwavering – we
                continually seek out fresh and innovative ideas to tackle the
                challenges our clients encounter today and anticipate those they
                might face in the future. Our approach is characterized by a
                willingness to explore new perspectives and to put our insights
                into practice, ensuring that our clients receive cutting-edge
                solutions tailored to their specific needs.
              </p>
             
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
            {/* Offer Start */}
            <div className="container-fluid bg-offer my-5 py-5">
        <div className="container py-5">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-9 text-center">
              <div
                className="section-title position-relative text-center mx-auto mb-4 pb-3"
                style={{ maxWidth: "680px" }}
              >
                <h2 className="text-primary font-secondary">
                  Why Choose Us
                </h2>
                <h1 className="display-4 text-uppercase text-white">
                  Why {companyname}
                </h1>
              </div>
              <p className="text-white mb-4">
              <p>At <strong>{companyname}</strong>, our mission is to provide transformative transportation and logistics solutions that play a pivotal role in the success of the businesses and individuals we serve. Our foundation is built upon three fundamental values: a unwavering commitment to our customers, an unwavering dedication to excellence, and a constant pursuit of innovative thinking. Whether your business is small or large, we offer tailored leasing solutions that adapt to your distinctive and ever-evolving requirements. </p>
<p>As a global leader in logistics, transportation, and distribution services, we tackle the transportation and logistics challenges faced by individuals, small enterprises, and intricate multinational corporations alike. Our goal is to foster efficient supply chains, streamline distribution processes, and facilitate seamless transportation for all our clients. With a focus on continuous improvement, we employ cutting-edge strategies that enhance your logistics operations and contribute to your overall success.</p>
              </p>
              <Link to="/About" className="btn btn-primary border-inner py-3 px-5 me-3">
                Learn More
              </Link>
              <Link to="/Contact" className="btn btn-dark border-inner py-3 px-5">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid service position-relative px-5 mt-5"
        style={{ marginBottom: "135px" }}
      >
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 col-md-6">
              <div className="bg-primary border-inner text-center text-white p-5">
                <h4 className="text-uppercase mb-3">Our Mission</h4>
                <p>
                  We are dedicated to fulfilling our commitments and providing
                  exceptional value-added services to our clients. Our aim is to
                  set new standards in quality, reliability, and consistency
                  within our industry. Through integrity, reliability, and
                  consistently meeting deadlines, we strive to forge enduring
                  relationships with our partners and clients.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="bg-primary border-inner text-center text-white p-5">
                <h4 className="text-uppercase mb-3">Our Vision</h4>
                <p>
                  Our aspiration is to establish a renowned logistics company
                  supported by a strong agent network and loyal clientele. Our
                  goal is to enhance your logistics efficiency as an omnichannel
                  service provider. We are dedicated to delivering unparalleled
                  service and striving for excellence as the ultimate and
                  reliable goods forwarder.
                </p>
              </div>
            </div>

            <div className="col-lg-12 col-md-6 text-center">
              <h1 className="text-uppercase text-light mb-4">
                Navigating the Future of Freight
              </h1>
              <Link
                to="/About"
                className="btn btn-primary border-inner py-3 px-5"
              >
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* Service Start */}
      {/* Team Start */}
      <div className="container-fluid py-5">
        <div className="container">
          <div
            className="section-title position-relative text-center mx-auto mb-5 pb-3"
            style={{ maxWidth: "600px" }}
          >
            <h2 className="text-primary font-secondary">Services</h2>
            <h1 className="display-4 text-uppercase">Freight Forwarding</h1>
          </div>
          <div className="row g-5">
            <div className="col-lg-4 col-md-6">
              <div className="team-item">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid w-100"
                    src="img/Air Freight.jpg"
                    alt=""
                  />
                 
                </div>
                <div className="bg-dark border-inner text-center p-4">
                <Link to="/Air"> <Link to="/Air"> <h4 className="text-uppercase text-primary">Air Freight</h4></Link></Link>
                  <p className="text-white m-0">Freight Forwarding</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="team-item">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid w-100"
                    src="img/Ocean Freight.jpg"
                    alt=""
                  />
                 
                </div>
                <div className="bg-dark border-inner text-center p-4">
                  <Link to="/Ocean"> <h4 className="text-uppercase text-primary">Ocean Freight</h4></Link>
                  <p className="text-white m-0">Freight Forwarding</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="team-item">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid w-100"
                    src="img/Customs Brokerage.jpg"
                    alt=""
                  />
                 
                </div>
                <div className="bg-dark border-inner text-center p-4">
                  <Link to="/Customs"> <h4 className="text-uppercase text-primary">Customs Brokerage</h4></Link>
                  <p className="text-white m-0">Freight Forwarding</p>
                </div>
              </div>
            </div>
            
           
          </div>
          
        </div>
        
      </div>
      <div className="text-center">
      <Link
                to="/Services"
                className="btn btn-primary border-inner py-3 px-5 text-center"
              >
                View All
              </Link>
      </div>

      <Footer />
    </>
  );
}
