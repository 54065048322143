import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid bg-dark bg-img p-5 mb-5">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="display-4 text-uppercase text-white">Air Freight</h1>
            <Link to="/Home">Home</Link>
            <i className="far fa-square text-primary px-2" />
            <a href>Air Freight</a>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 text-center">
              <h1 className="mb-4">Air Freight</h1>
              <img
                className="img-fluid rounded  wow zoomIn"
                data-wow-delay="0.1s"
                src="img/Air Freight.jpg"
              />
            </div>
            <div className="col-lg-12">
              <p>
                When you're faced with an international shipment that demands
                swift delivery within tight deadlines, look no further than{" "}
                {companyname}' direct freight services. Our direct approach
                ensures that your cargo is promptly loaded onto the earliest
                available flight, bypassing the need for consolidation. This
                option proves highly advantageous for those with larger
                shipments or time-critical deliveries, guaranteeing the prompt
                arrival of your goods.
              </p>

              <p>
                For shippers who are conscious of both time and cost factors, we
                offer consolidated services. This involves combining cargo from
                multiple shippers into a single load under a master air waybill,
                streamlining resources and processes for efficiency. If you're
                uncertain about whether direct shipping is the right fit for
                your requirements, our team is on hand to discuss your needs and
                help you determine the optimal method for transporting your
                cargo.
              </p>

              <p>
                At {companyname}, we understand that each shipment is unique,
                and our services are designed to cater to your specific demands.
                Whether your priority is speed or cost-effectiveness, our range
                of shipping solutions ensures a tailored approach that aligns
                with your goals. We are committed to providing reliable and
                effective logistics solutions that address your diverse shipping
                needs.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
