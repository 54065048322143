import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid bg-dark bg-img p-5 mb-5">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="display-4 text-uppercase text-white">
              Dangerous Cargo
            </h1>
            <Link to="/Home">Home</Link>
            <i className="far fa-square text-primary px-2" />
            <a href>Dangerous Cargo</a>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 text-center">
              <h1 className="mb-4">Dangerous Cargo</h1>
              <img
                className="img-fluid rounded  wow zoomIn"
                data-wow-delay="0.1s"
                src="img/Dangerous Cargo.jpg"
              />
            </div>
            <div className="col-lg-12">
              <p>
                Ensuring the safety of both transportation modes—air and sea—is
                of paramount importance, especially when dealing with hazardous
                materials that pose risks to aircraft, ships, crew, and
                passengers. Certain items fall under the category of dangerous
                goods, and their transportation by air and sea is either
                strictly prohibited or subject to stringent regulations. To
                address this challenge, industry authorities such as the
                International Air Transport Association (IATA) and the
                International Maritime Dangerous Goods (IMDG) Code play pivotal
                roles in advocating and implementing safe handling practices for
                these goods.
              </p>

              <p>
                IATA and IMDG take on the responsibility of establishing
                comprehensive guidelines, protocols, and regulations to
                guarantee the secure transportation of dangerous goods by air
                and sea. They offer a wealth of technical expertise, a diverse
                array of products, services, and specialized training programs
                tailored to meet the exacting demands of the industry. These
                initiatives are designed to uphold safety standards and align
                with the unique requirements posed by the transportation of
                hazardous materials.
              </p>

              <p>
                Through the establishment and promotion of rigorous standards,
                the industry has achieved an exceptional level of safety in
                handling dangerous goods. Stringent guidelines govern
                documentation, handling procedures, and training protocols.
                These standards, widely endorsed and implemented, have
                contributed to enhancing safety during the transportation of
                hazardous materials. {companyname}, holding
                accreditation from IATA and manned by qualified professionals
                with Dangerous Goods certifications, stands ready to assist you
                in safely shipping hazardous materials by air and sea, in
                adherence to the highest safety standards.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
