import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Services() {
  return (
    <>
      <Header />
      <div className="container-fluid bg-dark bg-img p-5 mb-5">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="display-6 text-uppercase text-white">
            Services
            </h1>
            <Link to="/Home">Home</Link>
            <i className="far fa-square text-primary px-2" />
            <a href>Services</a>
          </div>
        </div>
      </div>
      <div className="container-fluid about py-5">
        <div className="container">
          <div
            className="section-title position-relative text-center mx-auto mb-5 pb-3"
            style={{ maxWidth: "600px" }}
          >
            <h2 className="text-primary font-secondary">What We Do</h2>
            <h1 className="display-4 text-uppercase">Explore Our Services</h1>
          </div>
          <div className="tab-class text-center">
            <ul className="nav nav-pills d-inline-flex justify-content-center bg-dark text-uppercase border-inner p-4 mb-5">
              <li className="nav-item">
                <Link
                  className="nav-link text-white active"
                  data-bs-toggle="pill"
                  to="#tab-1"
                >
                  Freight Forwarding
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-white"
                  data-bs-toggle="pill"
                  to="#tab-2"
                >
                  Business Trading
                </Link>
              </li>
            </ul>
            <div className="tab-content">
              <div id="tab-1" className="tab-pane fade show p-0 active">
                <div className="row g-3">
                  <div className="col-lg-6">
                    <div className="d-flex h-100">
                      <div className="">
                        <img
                          className="img-fluid"
                          src="img/Air Freight.jpg"
                          alt=""
                          style={{ width: "220px" }}
                        />
                      </div>
                      <div className="d-flex flex-column justify-content-center text-start bg-secondary border-inner px-4">
                        <Link to="/Air">
                          <h5 className="text-uppercase">Air Freight</h5>
                        </Link>
                        <span>When you're faced with an international...
                </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="d-flex h-100">
                      <div className="flex-shrink-0">
                        <img
                          className="img-fluid"
                          src="img/Ocean Freight.jpg"
                          alt=""
                          style={{ width: "220px" }}
                        />
                      </div>
                      <div className="d-flex flex-column justify-content-center text-start bg-secondary border-inner px-4">
                        <Link to="/Ocean">
                          <h5 className="text-uppercase">Ocean Freight</h5>
                        </Link>
                        <span>Ocean Freight Shipping stands as a pinnacle...</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="d-flex h-100">
                      <div className="flex-shrink-0">
                        <img
                          className="img-fluid"
                          src="img/Customs Brokerage.jpg"
                          alt=""
                          style={{ width: "220px" }}
                        />
                      </div>
                      <div className="d-flex flex-column justify-content-center text-start bg-secondary border-inner px-4">
                        <Link to="/Customs">
                          <h5 className="text-uppercase">Customs Brokerage</h5>
                        </Link>
                        <span> International trade operates within a..</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="d-flex h-100">
                      <div className="flex-shrink-0">
                        <img
                          className="img-fluid"
                          src="img/Project Cargo Handling.jpg"
                          alt=""
                          style={{ width: "220px" }}
                        />
                      </div>
                      <div className="d-flex flex-column justify-content-center text-start bg-secondary border-inner px-4">
                        <Link to="/Project">
                          <h5 className="text-uppercase">
                            Project Cargo Handling
                          </h5>
                        </Link>
                        <span>When it comes to the intricate task of transporting..</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="d-flex h-100">
                      <div className="flex-shrink-0">
                        <img
                          className="img-fluid"
                          src="img/Dangerous Cargo.jpg"
                          alt=""
                          style={{ width: "220px" }}
                        />
                      </div>
                      <div className="d-flex flex-column justify-content-center text-start bg-secondary border-inner px-4">
                        <Link to="/Dangerous">
                          <h5 className="text-uppercase">Dangerous Cargo</h5>
                        </Link>
                        <span>Ensuring the safety of both transportation modes—air and...</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="d-flex h-100">
                      <div className="flex-shrink-0">
                        <img
                          className="img-fluid"
                          src="img/Cold Chain Logistic.jpg"
                          alt=""
                          style={{ width: "220px" }}
                        />
                      </div>
                      <div className="d-flex flex-column justify-content-center text-start bg-secondary border-inner px-4">
                        <Link to="/Cold">
                          <h5 className="text-uppercase">
                            Cold Chain Logistic
                          </h5>
                        </Link>
                        <span>The cold chain constitutes a critical aspect of modern...</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="tab-2" className="tab-pane fade show p-0">
                <div className="row g-3">
                  <div className="col-lg-6">
                    <div className="d-flex h-100">
                      <div className="flex-shrink-0">
                        <img
                          className="img-fluid"
                          src="img/Aerospace.jpg"
                          alt=""
                          style={{ width: "220px" }}
                        />
                      </div>
                      <div className="d-flex flex-column justify-content-center text-start bg-secondary border-inner px-4">
                        <Link to="/BusinessTrade">
                          <h5 className="text-uppercase">
                            Aerospace & Aviation
                          </h5>
                        </Link>
                        <span>{companyname} plays an integral role in bolstering the entire..</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="d-flex h-100">
                      <div className="flex-shrink-0">
                        <img
                          className="img-fluid"
                          src="img/Automotive.jpg"
                          alt=""
                          style={{ width: "220px" }}
                        />
                      </div>
                      <div className="d-flex flex-column justify-content-center text-start bg-secondary border-inner px-4">
                        <Link to="/BusinessTrade">
                          <h5 className="text-uppercase">Automotive</h5>
                        </Link>
                        <span> A key aspect of our service lies in Purchase Order (PO)
                Management...</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="d-flex h-100">
                      <div className="flex-shrink-0">
                        <img
                          className="img-fluid"
                          src="img/Beverages.jpg"
                          alt=""
                          style={{ width: "220px" }}
                        />
                      </div>
                      <div className="d-flex flex-column justify-content-center text-start bg-secondary border-inner px-4">
                        <Link to="/BusinessTrade">
                          <h5 className="text-uppercase">Beverages</h5>
                        </Link>
                        <span>Delivering excellence in the beverage industry, our specialized..</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="d-flex h-100">
                      <div className="flex-shrink-0">
                        <img
                          className="img-fluid"
                          src="img/Government & Defence.jpg"
                          alt=""
                          style={{ width: "220px" }}
                        />
                      </div>
                      <div className="d-flex flex-column justify-content-center text-start bg-secondary border-inner px-4">
                        <Link to="/BusinessTrade">
                          <h5 className="text-uppercase">
                            Government & Defence
                          </h5>
                        </Link>
                        <span>With a meticulous approach, our team of professionals takes..</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="d-flex h-100">
                      <div className="flex-shrink-0">
                        <img
                          className="img-fluid"
                          src="img/High Tech.jpg"
                          alt=""
                          style={{ width: "220px" }}
                        />
                      </div>
                      <div className="d-flex flex-column justify-content-center text-start bg-secondary border-inner px-4">
                        <Link to="/BusinessTrade">
                          <h5 className="text-uppercase">High Tech</h5>
                        </Link>
                        <span>Delivering comprehensive solutions that span the entire...</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="d-flex h-100">
                      <div className="flex-shrink-0">
                        <img
                          className="img-fluid"
                          src="img/Industrial.jpg"
                          alt=""
                          style={{ width: "220px" }}
                        />
                      </div>
                      <div className="d-flex flex-column justify-content-center text-start bg-secondary border-inner px-4">
                        <Link to="/BusinessTrade">
                          <h5 className="text-uppercase">Industrial</h5>
                        </Link>
                        <span>{companyname}'s comprehensive range of services tailored for..</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Products End */}
      <div className="container-fluid py-5">
        <div className="container">
          <div
            className="section-title position-relative text-center mx-auto mb-5 pb-3"
            style={{ maxWidth: "600px" }}
          >
            <h2 className="text-primary font-secondary">Services</h2>
            <h1 className="display-4 text-uppercase">Freight Forwarding</h1>
          </div>
          <div className="row g-5">
            <div className="col-lg-4 col-md-6">
              <div className="team-item">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid w-100"
                    src="img/Air Freight.jpg"
                    alt=""
                  />
                 
                </div>
                <div className="bg-dark border-inner text-center p-4">
                <Link to="/Air"> <Link to="/Air"> <h4 className="text-uppercase text-primary">Air Freight</h4></Link></Link>
                  <p className="text-white m-0">Freight Forwarding</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="team-item">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid w-100"
                    src="img/Ocean Freight.jpg"
                    alt=""
                  />
                 
                </div>
                <div className="bg-dark border-inner text-center p-4">
                  <Link to="/Ocean"> <h4 className="text-uppercase text-primary">Ocean Freight</h4></Link>
                  <p className="text-white m-0">Freight Forwarding</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="team-item">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid w-100"
                    src="img/Customs Brokerage.jpg"
                    alt=""
                  />
                 
                </div>
                <div className="bg-dark border-inner text-center p-4">
                  <Link to="/Customs"> <h4 className="text-uppercase text-primary">Customs Brokerage</h4></Link>
                  <p className="text-white m-0">Freight Forwarding</p>
                </div>
              </div>
            </div>
            
           
          </div>
          
        </div>
        
      </div>

      <Footer />
    </>
  );
}
