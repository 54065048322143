import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  return (
    <>
      {" "}
      <div
        className="container-fluid bg-img text-secondary"
        style={{ marginTop: "90px" }}
      >
        <div className="container">
          <div className="row gx-5">
            <div className="col-lg-4 col-md-6 mb-lg-n5">
              <div
                className="d-flex flex-column align-items-center justify-content-center text-center   border-inner p-3"
                style={{ background: "black" }}
              >
                <Link to="/Home" className="navbar-brand">
                  <h1 className="m-0 text-uppercase text-white">
                    Sunrise <br /> Enterprises
                  </h1>
                </Link>
                <p className="mt-3">
                  At {companyname}, we specialize in offering comprehensive
                  Freight Forwarding and business trade solutions that enable
                  you to expand your business's reach to every corner you
                  desire.. <br />
                  <br />
                </p>
              </div>
            </div>
            <div className="col-lg-8 col-md-6">
              <div className="row gx-5">
                <div className="col-lg-4 col-md-12 pt-5 mb-5">
                  <h4 className="text-primary text-uppercase mb-4">
                    Get In Touch
                  </h4>
                  <div className="d-flex mb-2">
                    <i className="bi bi-geo-alt text-primary me-2" />
                    <p className="mb-0">{companyaddress}</p>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-envelope-open text-primary me-2" />
                    <p className="mb-0">{companyemail}</p>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-telephone text-primary me-2" />
                    <p className="mb-0">{companynumber}</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                  <h4 className="text-primary text-uppercase mb-4">
                    Quick Links
                  </h4>
                  <div className="d-flex flex-column justify-content-start">
                    <Link className="text-secondary mb-2" to="/Home">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Home
                    </Link>
                    <Link className="text-secondary mb-2" to="/About">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      About Us{" "}
                    </Link>
                    <Link className="text-secondary mb-2" to="/Services">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Services
                    </Link>
                    <Link className="text-secondary mb-2" to="/Contact">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Contact
                    </Link>
                    <Link className="text-secondary mb-2" to="/Getquote">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Request Quote
                    </Link>
                    <Link className="text-secondary mb-2" to="/Privacy">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Privacy Policy
                    </Link>
                    <Link className="text-secondary mb-2" to="/Terms">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Terms
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                  <h4 className="text-primary text-uppercase mb-4">
                    What We Do
                  </h4>
                  <div className="d-flex flex-column justify-content-start">
                    <Link to="/Air" className="text-secondary mb-2"><i className="bi bi-arrow-right text-primary me-2" />
                      Air Freight
                    </Link>
                    <Link to="/Ocean" className="text-secondary mb-2"><i className="bi bi-arrow-right text-primary me-2" />
                      Ocean Freight
                    </Link>
                    <Link to="/Air" className="text-secondary mb-2"><i className="bi bi-arrow-right text-primary me-2" />
                      Customs Brokerage
                    </Link>
                    <Link to="/Air" className="text-secondary mb-2"><i className="bi bi-arrow-right text-primary me-2" />
                      Project Cargo Handling
                    </Link>
                    <Link to="/Air" className="text-secondary mb-2"><i className="bi bi-arrow-right text-primary me-2" />
                      Dangerous Cargo
                    </Link>
                    <Link to="/Air" className="text-secondary mb-2"><i className="bi bi-arrow-right text-primary me-2" />
                      Cold Chain Logistic
                    </Link>
                    <Link to="/BusinessTrade" className="text-secondary mb-2"><i className="bi bi-arrow-right text-primary me-2" />
                    Business Trading Services
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid text-secondary py-4"
        style={{ background: "#111111" }}
      >
        <div className="container text-center">
          <p className="mb-0">
            ©2023{" "}
            <Link className="text-white border-bottom" to="#">
              {companyname}
            </Link>
            . All Rights Reserved.
           
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
