import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid bg-dark bg-img p-5 mb-5">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="display-4 text-uppercase text-white">
              Customs Brokerage
            </h1>
            <Link to="/Home">Home</Link>
            <i className="far fa-square text-primary px-2" />
            <a href>Customs Brokerage</a>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 text-center">
              <h1 className="mb-4">Customs Brokerage</h1>
              <img
                className="img-fluid rounded  wow zoomIn"
                data-wow-delay="0.1s"
                src="img/Customs Brokerage.jpg"
              />
            </div>
            <div className="col-lg-12">
              <p>
                International trade operates within a framework of diverse trade
                laws and tariffs, strategically established by nations to
                regulate the flow of goods across borders. In the intricate
                landscape of global trade, our services emerge as a beacon of
                understanding and efficiency. With a deep understanding of the
                customs brokerage process in India and worldwide through our
                extensive network of partners, we navigate the complexities of
                international trade on behalf of our clients.
              </p>

              <p>
                Each nation defines its distinct set of regulations that govern
                the import and export of goods, constituting a critical facet of
                the trade landscape. In the dynamic realm of global trade, these
                regulations are subject to frequent amendments and updates. As
                adept clearance specialists, we shoulder the responsibility of
                meticulously overseeing the movement of goods, adeptly managing
                the import and export processes on behalf of our valued clients.
              </p>

              <p>
                Our expertise shines through as we harmonize the intricate
                interplay of regulations, documentation, and logistics to ensure
                seamless and compliant trade operations. With a commitment to
                excellence, we act as the conduit that bridges international
                trade intricacies, enabling our clients to engage in
                cross-border commerce with confidence and efficiency.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
