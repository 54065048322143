import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />
       {/* Page Header Start */}
       <div className="container-fluid bg-dark bg-img p-5 mb-5">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="display-4 text-uppercase text-white">Contact Us</h1>
              <Link to="/Home">Home</Link>
              <i className="far fa-square text-primary px-2" />
              <a href>Contact</a>
            </div>
          </div>
        </div>
        {/* Page Header End */}
        {/* Contact Start */}
        <div className="container-fluid  position-relative px-5" style={{marginTop: '90px'}}>
          <div className="container">
            <div className="row g-5 mb-5">
              <div className="col-lg-4 col-md-6">
                <div className="bg-primary border-inner text-center text-white p-5" style={{height:"300px"}}>
                  <i className="bi bi-geo-alt fs-1 text-white" />
                  <h6 className="text-uppercase my-2">Our Office</h6>
                  <span>{companyaddress}</span>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="bg-primary border-inner text-center text-white p-5" style={{height:"300px"}}>
                  <i className="bi bi-envelope-open fs-1 text-white" />
                  <h6 className="text-uppercase my-2">Email Us</h6>
                  <span>{companyemail}</span> 
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="bg-primary border-inner text-center text-white p-5" style={{height:"300px"}}>
                  <i className="bi bi-phone-vibrate fs-1 text-white" />
                  <h6 className="text-uppercase my-2">Call Us</h6>
                  <span>{companynumber}</span>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-6">
              <form
                action="/php/thankyou-contact.php "
                method="post"
                onSubmit={(event) => handleSubmit(event)}
              >
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="control-group">
                      <input
                        onChange={(event) => handleFnameChange(event)}
                        type="text"
                        id="fname"
                        required
                        name="fname"
                        className="form-control border-0 bg-contactlight"
                        value={fname}
                        placeholder="Enter your first name"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="control-group">
                      <input
                        onChange={(event) => handlelnameChange(event)}
                        className="form-control border-0 bg-contactlight"
                        type="text"
                        required
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="lname"
                        name="lname"
                        value={lname}
                        placeholder="Enter your last name"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="control-group">
                      <input
                        onChange={(event) => handleemailChange(event)}
                        className="form-control border-0 bg-contactlight"
                        type="email"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="email"
                        name="email"
                        value={email}
                        placeholder="Enter your Email"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="control-group">
                      <input
                        onChange={(event) => handlesubjectChange(event)}
                        className="form-control border-0 bg-contactlight"
                        type="text"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="subject"
                        name="subject"
                        value={subject}
                        placeholder="Enter subject"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="control-group">
                      <textarea
                        className="form-control border-0 bg-contactlight"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="message"
                        name="message"
                        rows={1}
                        placeholder="Enter Message"
                        defaultValue={""}
                        value={message}
                        onChange={(event) => handlemessageChange(event)}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <button
                      name="submit"
                      className="btn btn-primary w-100 py-3"
                      type="submit"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
              <h1>{result}</h1>
              </div>
            </div>
          </div>
        </div>
        

      <Footer />
    </>
  );
}
