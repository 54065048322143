import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  return (
    <>
  <div className="container-fluid px-0 d-none d-lg-block">
          <div className="row gx-0">
            <div className="col-lg-4 text-center bg-secondary py-3">
              <div className="d-inline-flex align-items-center justify-content-center">
                <i className="bi bi-envelope fs-1 text-primary me-3" />
                <div className="text-start">
                  <h6 className="text-uppercase mb-1">Email Us</h6>
                  <span>{companyemail}</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 text-center  border-inner py-3" style={{background:"black"}}>
              <div className="d-inline-flex align-items-center justify-content-center" >
                <Link to="/Home" className="navbar-brand">
                 <img src="img/logo.png" style={{height:"60px"}}></img>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 text-center bg-secondary py-3">
              <div className="d-inline-flex align-items-center justify-content-center">
                <i className="bi bi-phone-vibrate fs-1 text-primary me-3" />
                <div className="text-start">
                  <h6 className="text-uppercase mb-1">Call Us</h6>
                  <span>{companynumber}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Topbar End */}
        {/* Navbar Start */}
        <nav className="navbar navbar-expand-lg bg-dark navbar-dark shadow-sm py-3 py-lg-0 px-3 px-lg-0">
          <Link to="/Home" className="navbar-brand d-block d-lg-none">
          <img src="img/logo.png" style={{height:"60px"}}></img>
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto mx-lg-auto py-0">
              <Link to="/Home" className="nav-item nav-link active">Home</Link>
              <Link to="/About" className="nav-item nav-link">Who We Are</Link>
              
              <div className="nav-item dropdown">
                <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">What We Do</Link>
                <div className="dropdown-menu m-0">
                  <Link to="/Air" className="dropdown-item">Air Freight</Link>
                  <Link to="/Ocean" className="dropdown-item">Ocean Freight</Link>
                  <Link to="/Customs" className="dropdown-item">Customs Brokerage</Link>
                  <Link to="/Project" className="dropdown-item">Project Cargo Handling</Link>
                  <Link to="/Dangerous" className="dropdown-item">Dangerous Cargo</Link>
                  <Link to="/Cold" className="dropdown-item">Cold Chain Logistic</Link>

                </div>
              </div>
              <div className="nav-item dropdown">
                <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Business Trading Services</Link>
                <div className="dropdown-menu m-0">
                  <Link to="/BusinessTrade" className="dropdown-item">Aerospace & Aviation</Link>
                  <Link to="/BusinessTrade" className="dropdown-item">Automotive</Link>
                  <Link to="/BusinessTrade" className="dropdown-item">Beverages</Link>
                  <Link to="/BusinessTrade" className="dropdown-item">Government & Defence</Link>
                  <Link to="/BusinessTrade" className="dropdown-item">High Tech</Link>
                  <Link to="/BusinessTrade" className="dropdown-item">Industrial</Link>
                </div>
              </div>
              <Link to="/Contact" className="nav-item nav-link">Contact Us</Link>
              <Link to="/Getquote" className="btn btn-primary border-inner py-3 m-3 px-5">
               Request For Quote
              </Link>
            </div>
          </div>
        </nav>
    </>
  );
};

export default Header;
