import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-6 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              Air Freight
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <Link to="/Home">Home</Link>
                </li>

                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  Rail Freight
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 text-center">
              <h1 className="mb-4">Rail Freight</h1>
              <img
                className="img-fluid rounded  wow zoomIn"
                data-wow-delay="0.1s"
                src="img/rail.jpg"
              />
            </div>
            <div className="col-lg-12">
              <p>
                With a reputation for excellence in rail cargo services, our
                company stands as a beacon of reliability and efficiency. Our
                extensive experience in securely transporting consignments via
                rail transportation sets us apart in the industry. When you
                choose us, you're choosing a team of professionals dedicated to
                the safety and integrity of your goods throughout their journey.
                From meticulous packaging to the final delivery, we prioritize
                every detail to ensure a seamless and damage-free transit.
              </p>
              <p>
                At the heart of our rail cargo services lies a commitment to
                utmost care and precision. We understand the value of your
                cargo, and our team works tirelessly to uphold that value. With
                a keen eye on safety measures and strategic handling, we ensure
                that your goods are delivered to their intended destinations
                without any compromise. Our dedicated professionals leverage
                their expertise to execute each step of the process flawlessly,
                giving you the confidence that your products are in the best
                hands.
              </p>
              <p>
                In an era of advanced technology, monitoring your cargo's
                movement has never been easier. Our modern information
                management systems provide you with real-time updates on the
                progress of your consignment. You can track its journey from
                origin to destination, ensuring transparency and allowing you to
                make informed decisions. With our rail cargo services, you gain
                not only reliable transportation but also the peace of mind that
                comes from staying closely connected to your goods.
              </p>
              <p>
                We understand that timing is crucial, and quality is
                non-negotiable. Our rail cargo services are designed to optimize
                both factors. Your products will reach their intended markets
                swiftly, without compromising on their quality. From start to
                finish, we prioritize efficiency, safety, and precision. By
                choosing our rail cargo services, you're choosing a partner
                committed to delivering your goods with the highest standards of
                professionalism and care.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
